import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _41dcd2c6 = () => interopDefault(import('../src/pages/baggage-details/index.vue' /* webpackChunkName: "pages/baggage-details/index" */))
const _41d533f4 = () => interopDefault(import('../src/pages/booking-products/index.vue' /* webpackChunkName: "pages/booking-products/index" */))
const _6e23c230 = () => interopDefault(import('../src/pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _16928913 = () => interopDefault(import('../src/pages/downloads/index.vue' /* webpackChunkName: "pages/downloads/index" */))
const _71cf207e = () => interopDefault(import('../src/pages/guests-details/index.vue' /* webpackChunkName: "pages/guests-details/index" */))
const _0ecbb48e = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _e98ed510 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _906791c2 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _ae1aa39a = () => interopDefault(import('../src/pages/my-profile.vue' /* webpackChunkName: "pages/my-profile" */))
const _6c95d15f = () => interopDefault(import('../src/pages/search-booking.vue' /* webpackChunkName: "pages/search-booking" */))
const _5a58a749 = () => interopDefault(import('../src/pages/downloads/error.vue' /* webpackChunkName: "pages/downloads/error" */))
const _0e62261b = () => interopDefault(import('../src/pages/downloads/ticket.vue' /* webpackChunkName: "pages/downloads/ticket" */))
const _779ee0cf = () => interopDefault(import('../src/pages/guests-details/questionnaire.vue' /* webpackChunkName: "pages/guests-details/questionnaire" */))
const _0935eac0 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _8e19007e = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _090558b4 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _45241177 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/baggage-details",
    component: _41dcd2c6,
    name: "baggage-details"
  }, {
    path: "/booking-products",
    component: _41d533f4,
    name: "booking-products"
  }, {
    path: "/callback",
    component: _6e23c230,
    name: "callback"
  }, {
    path: "/downloads",
    component: _16928913,
    name: "downloads"
  }, {
    path: "/guests-details",
    component: _71cf207e,
    name: "guests-details"
  }, {
    path: "/login",
    component: _0ecbb48e,
    name: "login"
  }, {
    path: "/maintenance",
    component: _e98ed510,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _906791c2,
    name: "manage-booking"
  }, {
    path: "/my-profile",
    component: _ae1aa39a,
    name: "my-profile"
  }, {
    path: "/search-booking",
    component: _6c95d15f,
    name: "search-booking"
  }, {
    path: "/downloads/error",
    component: _5a58a749,
    name: "downloads-error"
  }, {
    path: "/downloads/ticket",
    component: _0e62261b,
    name: "downloads-ticket"
  }, {
    path: "/guests-details/questionnaire",
    component: _779ee0cf,
    name: "guests-details-questionnaire"
  }, {
    path: "/manage-booking/cancel",
    component: _0935eac0,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/update-details",
    component: _8e19007e,
    name: "manage-booking-update-details"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _090558b4,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _45241177,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
